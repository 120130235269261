// components/Header.tsx
import { NavBarCustomProps } from '@common/admin/appearance/types/appearance-editor-config';
import React from 'react';


interface componentProps {
  prop: NavBarCustomProps;
}
const Header: React.FC<componentProps> = ({ prop }) => {
  return (
    <header className="site-header header-style-two">
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-12">
            <div className="navigation-area">
              {/* Site Branding */}
              <div className="site-branding">
                <a href="creative-agency.html">
                  <img className="logo-dark" src={prop.logo} alt="Site Logo" />
                  <img className="logo-white" src={prop.logo} alt="Site Logo" />
                </a>
              </div>

              {/* Site Navigation */}
              {/* <div className="site-navigation">
                <nav className="navigation">
                  <div className="menu-wrapper">
                    <div className="menu-content">
                      <ul className="mainmenu">
                        {
                          prop.links.map((link, index) => (
                            <li key={index} >
                              <a className="nav-link" href={link.href}>{link.text}</a>
                            </li>
                          ))
                        }

                      </ul>
                    </div>
                  </div>
                </nav>
              </div> */}
              <div className="header-navigation-right">
                {/* <div className="btn-sign-area">
                  <a className="btn btn-primary rounded-pill" href="#">Purchase Now</a>
                </div>  */}
                <div className="hamburger-menus">
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="mobile-sidebar-menu sidebar-menu">
        <div className="overlaybg"></div>
      </div> */}
    </header>
  );
};

export default Header;