// components/AboutBlock.tsx
import { LandingPageContent } from '@app/landing/landing-page-content';
import { AboutBlockProps } from '@common/admin/appearance/types/appearance-editor-config';
import React from 'react';



interface AboutBlockComponentProps {
  about: AboutBlockProps;
}
const AboutBlock: React.FC<AboutBlockComponentProps> = ({ about }) => {
  return (
    <div className="about-block pd-b-130" id={about.id}>
      <div className="element-group">
        <div className="element one"></div>
        <div className="element two animation-moving-left-right-two"></div>
      </div>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-6">
            <div className="row align-items-center">
              <div className="col-lg-7 col-6">
                <img
                  className="radius-4 aos-item"
                  data-aos="fade-left"
                  data-aos-duration="500"
                  data-aos-once="true"
                  src={about.images.imageOne}
                  alt="About-img"
                />
              </div>
              <div className="col-lg-5 col-6">
                <img
                  className="radius-4 aos-item"
                  data-aos="fade-right"
                  data-aos-duration="600"
                  data-aos-once="true"
                  src={about.images.imageTwo}
                  alt="About-img-2"
                />
              </div>
            </div>
          </div>
          <div className="col-lg-5 offset-lg-1 col-md-6">
            <div className="our-content-area mrt-30">
              <div className="section-title mrb-25">
                <h4
                  className="sub-title bg-primary aos-item"
                  data-aos="fade-up"
                  data-aos-duration="300"
                  data-aos-once="true"
                >
                  {about.textButton}
                </h4>
                <h2
                  className="title-main aos-item"
                  data-aos="fade-up"
                  data-aos-duration="400"
                  data-aos-once="true"
                >
                  {about.title} <span className="text-gradient">{about.titleDecorater}</span> {about.title2}
                </h2>
                <div
                  className="title-text aos-item"
                  data-aos="fade-up"
                  data-aos-duration="500"
                  data-aos-once="true"
                >
                  <p>
                    {about.description}
                  </p>
                </div>
              </div>
              <div className="skill-list aos-item" data-aos="fade-up" data-aos-duration="600" data-aos-once="true">
                {
                  about.skills.map((skill, index) => (
                    <div key={index} className="single-skill">
                      <h3 className="skill-title">{skill.title}</h3>
                      <div className="progress">
                        <div className="progress-bar" style={{ width: skill.progress+'%' }}>
                          <span>{skill.progress}%</span>
                        </div>
                      </div>
                    </div>
                  ))
                }


              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutBlock;