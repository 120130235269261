// components/PortfolioBlock.tsx
import { ServicesBlockProps } from '@common/admin/appearance/types/appearance-editor-config';
import React from 'react';

interface componentProps {
  prop: ServicesBlockProps;
}
const ServicesBlock: React.FC<componentProps> = ({ prop }) => {

console.log({prop});

  return (
    <div className="services-block style-three ptb-130" id={prop.id}>
      <div className="element-group">
        <div className="element one"></div>
        <div className="element two animation-moving-left-right-two"></div>
      </div>
      <div className="container ml-b-30">
        <div className="row justify-content-center">
          <div className="col-lg-10">
            <div className="section-title txt-center">
              <h4
                className="sub-title bg-primary aos-item"
                data-aos="fade-up"
                data-aos-duration="300"
                data-aos-once="true"
              >
                Services
              </h4>
              <h2
                className="title-main aos-item"
                data-aos="fade-up"
                data-aos-duration="400"
                data-aos-once="true"
              >
                {prop.title} <span className="text-gradient">{prop.titleDecorate} </span> {prop.subtitle} 
              </h2>
              <p
                className="description aos-item"
                data-aos="fade-up"
                data-aos-duration="500"
                data-aos-once="true"
              >
                {prop.description} 
              </p>
            </div>
          </div>
        </div>
        <div className="row">
          {
          prop.items.map((service, index) => (
            <div
              key={index}
              className="col-lg-3 col-md-6 aos-item"
              data-aos="fade-up"
              data-aos-duration={aumentador(index)}
              data-aos-once="true"
            >
              <div className="service-item style-one ">
                <div className="service-icon">
                  <i className={`icon ${service.icon} color-primary`}></i>
                </div>
                <div className="service-info">
                  <h3 className="title">{service.title}</h3>
                  <p >{service.description}</p>
                  {/* <a className="read-more-text" href={service.link}>
                    Read More <i className="icofont-long-arrow-right"></i>
                  </a> */}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

function aumentador(index: number) {
  return `${500 * (index + 1)}`;
}

export default ServicesBlock;