import React, { useEffect, useState } from 'react';
import './splash.css';

interface SplashProps {
  onFinishLoading: () => void;
}

const Splash: React.FC<SplashProps> = ({ onFinishLoading }) => {
  const [loading, setLoading] = useState(true);


  useEffect(() => {
    let mounted = true;

    const checkAssetsLoaded = () => {
      return document.readyState === 'complete';
    };

    const handleLoading = () => {
      if (mounted && checkAssetsLoaded()) {
        // Add a small delay to ensure smooth transition
        setTimeout(() => {
          if (mounted) {
            setLoading(false);
            onFinishLoading();
          }
        }, 500);
      }
    };

    // Add event listeners
    window.addEventListener('load', handleLoading);
    window.addEventListener('readystatechange', handleLoading);

    // Check immediately in case assets are already loaded
    handleLoading();

    // Cleanup
    return () => {
      mounted = false;
      window.removeEventListener('load', handleLoading);
      window.removeEventListener('readystatechange', handleLoading);
    };
  }, [onFinishLoading]);

  if (!loading) return null;


  return (
    <div className="splash-screen">
      <div className="splash-content">
        <img src="/images/logo-light.png" alt="Logo" className="splash-logo" />
        <div className="splash-loader"></div>
      </div>

    </div>
  );
};

export default Splash;