// components/VideoBlock.tsx
import { VideoBlockProps } from '@common/admin/appearance/types/appearance-editor-config';
import React from 'react';

interface componentProps {
  prop: VideoBlockProps;
}

const VideoBlock: React.FC<componentProps> = ({prop}) => {

  
  return (
    <div className="video-block" id={prop.id}>
      <div className="element-group">
        <div className="element one"></div>
        <div className="element two animation-moving-left-right-two"></div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div
              className="promo-video-area aos-item"
              data-aos="fade-up"
              data-aos-duration="300"
              data-aos-once="true"
            >
              <div className="video-thumb bg-overlay-gradient radius-4">
                <img src={prop.videoThumbnail} alt="Video Thumbnail" />
              </div>
              <div className="promo-video">
                <a
                  href={prop.videoUrl}
                  className="video-btn video-popup video-btn-gradient"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span className="icofont-ui-play"></span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoBlock;